/* STORE */
var outProductID;
var outProductVO;
var outProductQuantity;
var outCallback;

function currentCart(action)
{
	if(!$.cookie("cart"))
	{
		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "currentCart"},
			dataType: 'json'
		}).done(function(cartID){
			if(action)
			{
				switch(action)
				{
					case "addProduct":
					addProduct(outProductID,outCallback, outProductQuantity)
					break;

					case "addCustomProduct":
					addCustomProduct(outProductVO,outCallback)
					break;
				}
			}
		});
	}
	else
	{
		return $.cookie("cart");
	}
}

function getFullCart(callback)
{
	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "getFullCart"},
			dataType: 'json'
	}).done(callback);
}

function addProduct(productID, callback, productQuantity)
{
	if($.cookie("cart"))
	{
		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "addProduct", productID: productID, productQuantity: productQuantity},
			dataType: 'json'
		}).done(callback);
	}
	else
	{
		outProductID = productID;
		outCallback = callback;
		outProductQuantity = productQuantity;
		currentCart('addProduct');
	}
}
function deleteProduct(productID, callback)
{
	if($.cookie("cart"))
	{
		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "deleteProduct", productID: productID},
			dataType: 'json'
		}).done(callback);
	}
	else
	{
		outProductID = productID
		outCallback = callback;
		currentCart('addProduct');
	}
}

function addCustomProduct(productVO,callback)
{
	//alert($.cookie("cart"))
	// custom image goes as optional1
	if($.cookie("cart"))
	{
		//alert("tem carrinho")

		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "addCustomProduct", productVO: productVO},
			dataType: 'json'
		}).done(callback)

	}
	else
	{
		//alert("nao tem carrinho")

		outProductVO = productVO;
		outCallback = callback;
		currentCart('addCustomProduct');

	}
}

function clearCart()
{
	if($.cookie("cart"))
	{
		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "clearCart"},
			dataType: 'json'
		}).done(function( value ) {

		})
	}
}
function calculateWeight(destinyCountry, weight, callback, totalCost)
{
	var data = new Object();
	if(totalCost != undefined)
	{
		data = {action: "calculateWeight", destinyCountry: destinyCountry, weight: weight, totalCost: totalCost}
	}
	else
	{
		data = {action: "calculateWeight", destinyCountry: destinyCountry, weight: weight}
	}
	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: data,
			dataType: 'json'
	}).done(callback)
}

function updateProductQuantity(productID, quantity, callback)
{
	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "updateProductQuantity", productID: productID, quantity: quantity},
			dataType: 'json'
	}).done(callback)
}
function updateProductQuantityByProductID(productID, quantity, callback)
{
	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "updateProductQuantityByProductID", productID: productID, quantity: quantity},
			dataType: 'json'
	}).done(callback)
}
function updateUserID(userID, callback)
{
	if($.cookie("cart"))
	{
		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "updateUserID", userID: userID},
			dataType: 'json'
		}).done(callback)
	}
}
function setOrder(userVO, callback)
{
	if($.cookie("cart"))
	{
		$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/store/store.engine.php",
			data: {action: "setOrder", user: userVO},
			dataType: 'json'
		}).done(callback)
	}
}
function storeEngineInterface(data, callback)
{
	$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/store/store.engine.php",
		data: data,
		dataType: 'json'
	}).done(function(response){
		callback(response);
	})
}